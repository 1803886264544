import React from 'react';
import { styled } from '@mui/material/styles';
import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

interface StyledListItemButton extends ListItemButtonProps {
  single?: boolean;
  nested?: boolean;
}

const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'single' && prop !== 'nested'
})<StyledListItemButton>(({ single, nested, theme }) => {
  const textStyle = {
    typography: {
      '& .MuiTypography-root': {
        paddingLeft: theme.spacing(1.5),
        fontFamily: `'Somar Sans', sans-serif`,
        fontSize: '1.4rem',
        lineHeight: '19px',
        color: theme.palette.grey[400],
        fontWeight: 400
      }
    },
    activeTypography: {
      '& .MuiTypography-root': {
        fontWeight: 600,
        color: theme.palette.primary.main
      }
    }
  };

  return {
    borderRadius: theme.spacing(1),
    '& .MuiListItemText-root': {
      margin: 0,
      ...textStyle.typography
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3)
    },
    '&:hover': {
      backgroundColor: single ? theme.palette.common.white : theme.palette.action.hover,
      ...textStyle.activeTypography
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.active,
      ...textStyle.activeTypography,
      '&:Hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    ...(single && {
      marginBottom: theme.spacing(1)
    }),
    ...(nested && {
      padding: '0.4rem 0.9rem 0.4rem 5.2rem',
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          ...textStyle.typography['& .MuiTypography-root'],
          paddingLeft: 0
        }
      }
    })
  };
});

export default ListItemButton;
