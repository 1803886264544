import React, { useEffect, useState } from 'react';
import { getMenuList } from '../../../../services/routes/routes.service';
import { MenuConfigItem } from '../../../../interfaces/side-menu-item.interface';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import MenuItem from './menu-item';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

interface SideMenuContentProps {
  isCollapsed: boolean;
}

const SideMenuContent = ({ isCollapsed }: SideMenuContentProps) => {
  const theme = useTheme();
  const [MenuItems, setMenuItems] = useState<MenuConfigItem[]>([]);
  const userRole = useSelector((state: RootState) => state.auth.role);
  const userTeam = useSelector((state: RootState) => state.auth.team);

  useEffect(() => {
    const MenuItemsList = getMenuList(userRole, userTeam?.name);
    setMenuItems(MenuItemsList);
  }, []);

  return (
    <List
      component="nav"
      sx={{
        p: '0px 10px',
        pt: { xs: '7rem' },
        width: isCollapsed ? '64px' : '256px',
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        overflowX: 'hidden'
      }}
    >
      {MenuItems.map((item, key) => {
        return <MenuItem item={item} key={key} isCollapsed={isCollapsed} />;
      })}
    </List>
  );
};

export default SideMenuContent;
