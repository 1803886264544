import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ExpandMore from '../../../../../assets/svgs/side-bar-icons/expand-more.icon.svg?react';
import ExpandLess from '../../../../../assets/svgs/side-bar-icons/expand-less.icon.svg?react';
import { CustomMenuItemProps } from '../shared/types/types';
import ListItemButton from '../shared/components/styled-list-item';
import { StyledListItemIcon } from '../shared/components/styled-list-item-icon';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';

const MultiLevel = (props: CustomMenuItemProps) => {
  const { item, setIsActive, isActive, selectedIndex, setSelectedIndex, isCollapsed } = props;
  const { CHILDREN: children } = item;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { logo: Logo, activeLogo: ActiveLogo } = item.ICON;

  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    children?.map((nestedItem) => {
      if (pathname.includes(nestedItem.NAME) && pathname.split('/')[1] !== 'dashboard')
        setOpen(true);
    });
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isCollapsed) {
      setAnchorEl(event.currentTarget);
    } else {
      setOpen(!open);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemName: string
  ) => {
    setSelectedIndex(itemName);
    if (isCollapsed) {
      handlePopoverClose();
    }
  };

  const handleMouseEnter = useCallback(() => {
    setIsActive({ [item.NAME]: true });
  }, [item]);

  const handleMouseLeave = useCallback(() => {
    setIsActive({ [item.NAME]: false });
  }, [item]);

  const popoverOpen = Boolean(anchorEl);

  const menuItem = (
    <ListItemButton
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        display: 'flex',
        alignItems: 'center !important',
        justifyContent: 'center !important'
      }}
    >
      <StyledListItemIcon>
        {isActive[item.NAME] || open ? <ActiveLogo /> : <Logo />}
      </StyledListItemIcon>
      {!isCollapsed && (
        <>
          <ListItemText primary={item.DISPLAY_NAME} />
          {open ? <ExpandMore /> : <ExpandLess />}
        </>
      )}
    </ListItemButton>
  );

  const renderSubMenuItem = (nestedItem: any, key: number, selected: boolean) => {
    const content = (
      <ListItemButton
        nested
        key={key}
        onClick={(e) => {
          handleListItemClick(e, nestedItem.NAME);
          navigate(nestedItem.FULL_PATH);
        }}
        selected={selected}
        sx={{
          pl: 4
        }}
      >
        <ListItemText
          primary={nestedItem.DISPLAY_NAME}
          sx={{
            '& .MuiTypography-root': {
              fontSize: '0.875rem',
              fontWeight: selected ? 600 : 400
            }
          }}
        />
      </ListItemButton>
    );

    return isCollapsed ? (
      <Tooltip key={key} title={nestedItem.DISPLAY_NAME} placement="right">
        {content}
      </Tooltip>
    ) : (
      content
    );
  };

  const childrenList = (
    <List
      component="div"
      disablePadding
      sx={{
        '& .MuiListItemButton-root': {
          pl: isCollapsed ? 2 : 4
        }
      }}
    >
      {children?.map((nestedItem, key) => {
        const selected =
          pathname.split('/')[2] === 'dashboard'
            ? selectedIndex === nestedItem.NAME ||
              nestedItem.NAME == `${pathname.split('/')[1]}/${pathname.split('/')[2]}`
            : pathname.split('/')[1] !== 'dashboard' &&
              (selectedIndex === nestedItem.NAME || pathname.split('/').includes(nestedItem.NAME));

        return renderSubMenuItem(nestedItem, key, selected);
      })}
    </List>
  );

  return (
    <>
      {isCollapsed ? (
        <>
          <Tooltip title={item.DISPLAY_NAME} placement="right">
            {menuItem}
          </Tooltip>
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              sx: {
                width: 200,
                ml: 1,
                '& .MuiList-root': {
                  p: 0
                }
              }
            }}
          >
            {childrenList}
          </Popover>
        </>
      ) : (
        <Box
          sx={{
            bgcolor: open ? theme.palette.action.active : theme.palette.common.white,
            borderRadius: theme.spacing(1),
            mb: theme.spacing(1)
          }}
        >
          {menuItem}
          <Collapse in={open} timeout="auto" unmountOnExit>
            {childrenList}
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default MultiLevel;
