// import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ListItemButton from '../shared/components/styled-list-item';
import { CustomMenuItemProps } from '../shared/types/types';
import Tooltip from '@mui/material/Tooltip';
import { StyledListItemIcon } from '../shared/components/styled-list-item-icon';

const SingleLevel = (props: CustomMenuItemProps): JSX.Element => {
  const { item, setIsActive, isActive, selectedIndex, setSelectedIndex, isCollapsed } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { logo: Logo, activeLogo: ActiveLogo } = item.ICON;

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: string
  ) => {
    setSelectedIndex(index);
  };

  const handleMouseEnter = useCallback(() => {
    setIsActive({ [item.NAME]: true });
  }, [item]);

  const handleMouseLeave = useCallback(() => {
    setIsActive({ [item.NAME]: false });
  }, [item]);

  const selected = selectedIndex === item.NAME || item.NAME == pathname.split('/')[1];

  const menuItem = (
    <ListItemButton
      single
      onClick={(e) => {
        handleListItemClick(e, item.NAME);
        navigate(item.FULL_PATH);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      selected={selected}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <StyledListItemIcon>
        {isActive[item.NAME] || selected ? <ActiveLogo /> : <Logo />}
      </StyledListItemIcon>
      {!isCollapsed && <ListItemText primary={item.DISPLAY_NAME} />}
    </ListItemButton>
  );

  return isCollapsed ? (
    <Tooltip title={item.DISPLAY_NAME} placement="right">
      {menuItem}
    </Tooltip>
  ) : (
    menuItem
  );
};

export default SingleLevel;
