import { ApiResponse } from 'apisauce';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment-timezone';
import { SelectOptionsProps } from '../../../../../shared/types/shared-types/select.type';
import { FinanceApplicationReportTableQuery } from '../../types/finance-application-report-payload.type';
import { ErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import errorHelper from '../../../../../shared/services/helpers/error-helper/error-helper.service';

export const findOptionById = (options: { id: string; label: string }[], id: string) =>
  _.find(options, { id });

type OptionsType = {
  statusSelectOptions: SelectOptionsProps[];
  carofiAgentsSelectOptions: SelectOptionsProps[];
  financeMerchantSelectOptions: SelectOptionsProps[];
  customerSourceItems: SelectOptionsProps[];
};

export const mapValuesToQuery = (
  values: FinanceApplicationReportTableQuery,
  options: OptionsType
) => {
  const { startDate, endDate, status, agentId, financialMerchantId, customerSource, ...rest } =
    values;

  return {
    ...rest,
    ...(startDate && { startDate: moment(startDate) }),
    ...(endDate && { endDate: moment(endDate) }),
    ...(status && {
      status: status.map((id) => findOptionById(options.statusSelectOptions, id)).filter(Boolean)
    }),
    ...(agentId && {
      agent: agentId
        .map((id) => findOptionById(options.carofiAgentsSelectOptions, id))
        .filter(Boolean)
    }),
    ...(financialMerchantId && {
      financialMerchantId: financialMerchantId
        .map((id) => findOptionById(options.financeMerchantSelectOptions, id))
        .filter(Boolean)
    }),
    ...(customerSource && {
      customerSource: customerSource
        .map((id) => findOptionById(options.customerSourceItems, id))
        .filter(Boolean)
    })
  };
};

// a generic type that takes two parameters, query and sort, and returns an object with those properties
type ExportPayLoad<Q = Record<string, unknown>, S = Record<string, number>> = {
  query?: Q;
  sort?: S;
  limit?: number;
  skip?: number;
};

// a generic type that takes a type T and returns an object with the query and sort properties
type WithQueryAndSort<T extends ExportPayLoad> = Pick<T, 'query' | 'sort'>;

export async function exportData<T extends ExportPayLoad<any, any>>(
  apiCall: (payload: WithQueryAndSort<T>) => Promise<ApiResponse<Blob, ErrorInfo>>,
  payload: WithQueryAndSort<T>,
  containerName: string,
  fileNamePrefix: string
): Promise<ErrorInfo | undefined> {
  const response = await apiCall(payload);

  if ('ok' in response && response.ok) {
    const { data } = response;
    if (data instanceof Blob) {
      const blob = new Blob([data]);
      FileSaver.saveAs(
        blob,
        `${fileNamePrefix}-${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}.xlsx`
      );
    } else {
      return errorHelper(0, '', containerName, apiCall.name);
    }
  } else {
    return errorHelper(
      Number(response.status),
      response.data?.errorCode ? String(response.data.errorCode || '') : response.problem,
      containerName,
      apiCall.name
    );
  }
}
