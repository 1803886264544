import { ThemeOptions } from '@mui/material';
import { merge } from 'lodash';
import AppBar from './appBar';
import Avatar from './avatar';
import Button from './button';
import IconButton from './iconButton';
import ListItemButton from './listItemButton';
import TextField from './textField';
import ToolBar from './toolBar';
import RangeDatePicker from './rangePicker';

const fontFamily = "'Somar Sans', Helvetica, Arial, sans-serif";

// Base component overrides
const baseComponents = (theme: ThemeOptions) => ({
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  },
  MuiList: {
    styleOverrides: {
      root: {
        fontFamily
      }
    }
  }
});

export default function ComponentsOverrides(theme: ThemeOptions) {
  return merge(
    baseComponents(theme),
    Button(theme),
    AppBar(theme),
    IconButton(theme),
    ToolBar(theme),
    ListItemButton(theme),
    Avatar(theme),
    TextField(theme),
    RangeDatePicker(theme)
  );
}
