import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const CalculatorContainer = React.lazy(() => import('../../containers/home/calculator.container'));

export const CatalogueRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate to={(APP_ROUTES.CATALOGUE_MODULE.CHILDREN as RouteConfig).CALCULATOR.FULL_PATH} />
    )
  },
  {
    path: (APP_ROUTES.CATALOGUE_MODULE.CHILDREN as RouteConfig).CALCULATOR.FULL_PATH,
    element: (
      // <React.Suspense fallback={<div>Loading...</div>}>
      <DefaultLayout
        authenticationRequired={true}
        permittedList={[
          UserRole.SALES_AGENT,
          UserRole.SALES_MANAGER,
          UserRole.E_COMMERCE_FINANCE,
          UserRole.E_COMMERCE_AUTO,
          UserRole.E_COMMERCE_MANAGER,
          UserRole.FINANCE_OFFICER,
          UserRole.OPERATION_MANAGER,
          UserRole.ADMIN
        ]}
        component={CalculatorContainer}
      />
      // </React.Suspense>
    )
  }
];
