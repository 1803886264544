import { useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider, ThemeOptions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides/index';
import zIndex from './zIndex';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

// Ensure consistent font family string
const fontFamily = "'Somar Sans', Helvetica, Arial, sans-serif";

export default function CustomTheme({ children }: { children: JSX.Element }) {
  const theme = Palette();

  const themeTypography = Typography(fontFamily);
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 380,
          md: 768,
          lg: 992,
          xl: 1536
        }
      },
      spacing: 8,
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
      zIndex: zIndex(theme),
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '*': {
              fontFamily
            },
            body: {
              fontFamily
            }
          }
        }
      }
    }),
    [theme, themeTypography, themeCustomShadows]
  );

  const themes = createTheme(themeOptions);
  themes.components = {
    ...themes.components,
    ...componentsOverride(themes)
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
