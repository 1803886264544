import DashboardLogo from '../../assets/svgs/side-bar-icons/dashboard.icon.svg?react';
import ActiveDashboardLogo from '../../assets/svgs/side-bar-icons/dashboard-active.icon.svg?react';
import PartnersLogo from '../../assets/svgs/side-bar-icons/partners.icon.svg?react';
import ActivePartnersLogo from '../../assets/svgs/side-bar-icons/partners-active.icon.svg?react';
import OperationsLogo from '../../assets/svgs/side-bar-icons/operations.icon.svg?react';
import ActiveOperationsLogo from '../../assets/svgs/side-bar-icons/operations-active.icon.svg?react';
// import PaymentsLogo from '../../assets/svgs/side-bar-icons/payments.icon.svg?react';
// import ActivePaymentsLogo from '../../assets/svgs/side-bar-icons/payments-active.icon.svg?react';
import ProductsLogo from '../../assets/svgs/side-bar-icons/products.icon.svg?react';
import ActiveProductsLogo from '../../assets/svgs/side-bar-icons/products-active.icon.svg?react';
import CustomersLogo from '../../assets/svgs/side-bar-icons/customers.icon.svg?react';
import ActiveCustomersLogo from '../../assets/svgs/side-bar-icons/customers-active.icon.svg?react';
import ReportsLogo from '../../assets/svgs/side-bar-icons/reports.icon.svg?react';
import ActiveReportsLogo from '../../assets/svgs/side-bar-icons/reports-active.icon.svg?react';
import SalesLogo from '../../assets/svgs/side-bar-icons/sales-management.icon.svg?react';
import CatalogueLogo from '../../assets/svgs/side-bar-icons/catalogue.icon.svg?react';
import ActiveCatalogueLogo from '../../assets/svgs/side-bar-icons/catalogue-active.icon.svg?react';
import ActiveSalesLogo from '../../assets/svgs/side-bar-icons/sales-management-active.icon.svg?react';
import ActiveCommunicationLogo from '../../assets/svgs/side-bar-icons/communication-active.icon.svg?react';
import CommunicationLogo from '../../assets/svgs/side-bar-icons/communication.icon.svg?react';
import SettingsLogo from '../../assets/svgs/side-bar-icons/settings.icon.svg?react';
import ActiveSettingsLogo from '../../assets/svgs/side-bar-icons/settings-active.icon.svg?react';

import { MenuItems } from '../enums/side-menu.enum';
import { SideMenuIconProps } from '../types/shared-types/side-menu.type';

export type MenuIconsProps = {
  [key in MenuItems]: SideMenuIconProps;
};

export const MenuIcons: MenuIconsProps = {
  DASHBOARD_MODULE: { logo: DashboardLogo, activeLogo: ActiveDashboardLogo },
  [MenuItems.CATALOGUE_MODULE]: { logo: CatalogueLogo, activeLogo: ActiveCatalogueLogo },
  [MenuItems.LEADS]: { logo: CustomersLogo, activeLogo: ActiveCustomersLogo },
  [MenuItems.CUSTOMERS]: { logo: CustomersLogo, activeLogo: ActiveCustomersLogo },
  [MenuItems.OPERATIONS]: { logo: OperationsLogo, activeLogo: ActiveOperationsLogo },
  // PAYMENTS_MODULE: { logo: PaymentsLogo, activeLogo: ActivePaymentsLogo },
  [MenuItems.PARTNERS]: { logo: PartnersLogo, activeLogo: ActivePartnersLogo },
  [MenuItems.PRODUCTS]: { logo: ProductsLogo, activeLogo: ActiveProductsLogo },
  [MenuItems.REPORTS]: { logo: ReportsLogo, activeLogo: ActiveReportsLogo },
  [MenuItems.USERS]: { logo: CustomersLogo, activeLogo: ActiveCustomersLogo },
  [MenuItems.COMMUNICATION]: { logo: CommunicationLogo, activeLogo: ActiveCommunicationLogo },
  [MenuItems.SALES]: { logo: SalesLogo, activeLogo: ActiveSalesLogo },
  [MenuItems.SETTINGS]: { logo: SettingsLogo, activeLogo: ActiveSettingsLogo }
};
