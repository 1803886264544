import Box from '@mui/material/Box';
import SideMenuContent from './components/side-menu-content';
import Drawer from '../../core-ui/drawer/drawer.component';
import { IconButton, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState, useEffect } from 'react';

interface SideMenuProps {
  mobileOpen: boolean;
  setMobileOpen: (mobileOpen: boolean) => void;
}

const MENU_COLLAPSE_KEY = 'sideMenuCollapsed';

const SideMenu = ({ mobileOpen, setMobileOpen }: SideMenuProps) => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const savedState = localStorage.getItem(MENU_COLLAPSE_KEY);
    return savedState ? JSON.parse(savedState) : false;
  });

  const expandedWidth = 256;
  const collapsedWidth = 64;
  const drawerWidth = isCollapsed ? collapsedWidth : expandedWidth;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCollapseToggle = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem(MENU_COLLAPSE_KEY, JSON.stringify(newState));
  };

  // Sync collapse state with localStorage when component mounts
  useEffect(() => {
    const savedState = localStorage.getItem(MENU_COLLAPSE_KEY);
    if (savedState !== null) {
      setIsCollapsed(JSON.parse(savedState));
    }
  }, []);

  return (
    <Box
      component="nav"
      sx={{
        width: { xs: 0, md: drawerWidth },
        flexShrink: { xs: 0 },
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
      }}
      aria-label="SideMenu"
    >
      <Drawer
        config={{ width: drawerWidth, anchor: 'left', isOpen: mobileOpen, appearOverAppbar: false }}
        eventHandlers={{ handleClose: handleDrawerToggle }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <SideMenuContent isCollapsed={isCollapsed} />
          <Box sx={{ marginTop: 'auto' }}>
            <Divider />
            <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleCollapseToggle}>
                {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        config={{
          width: drawerWidth,
          variant: 'permanent',
          anchor: 'left',
          appearOverAppbar: false
        }}
        styles={{
          display: { xs: 'none', md: 'flex' },
          '& .MuiDrawer-paper': {
            transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            overflowX: 'hidden',
            width: drawerWidth
          }
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <SideMenuContent isCollapsed={isCollapsed} />
          <Box sx={{ marginTop: 'auto' }}>
            <Divider />
            <Box
              sx={{ p: 1, display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-end' }}
            >
              <IconButton
                onClick={handleCollapseToggle}
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'action.hover'
                  }
                }}
              >
                {isCollapsed ? (
                  <ChevronRightIcon color="primary" />
                ) : (
                  <ChevronLeftIcon color="primary" />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideMenu;
