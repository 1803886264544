import { APP_ROUTES } from '../config/routes-config';
import { MenuConfigItem } from '../interfaces/side-menu-item.interface';

import DashboardLogo  from "../../assets/svgs/side-bar-icons/dashboard.icon.svg?react";

export const sideMenuMapper = (routeItem: string): MenuConfigItem => {
  return {
    NAME: APP_ROUTES[routeItem].NAME,
    DISPLAY_NAME: APP_ROUTES[routeItem].DISPLAY_NAME,
    ICON: APP_ROUTES[routeItem].ICON ?? { logo: DashboardLogo, activeLogo: DashboardLogo },
    PATH: APP_ROUTES[routeItem].PATH,
    FULL_PATH: APP_ROUTES[routeItem].FULL_PATH,
    PARENT: APP_ROUTES[routeItem].PARENT,
    PERMITTED_ROLES: APP_ROUTES[routeItem].PERMITTED_ROLES,
    PERMITTED_TEAMS: APP_ROUTES[routeItem].PERMITTED_TEAMS
  };
};
