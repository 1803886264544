import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';

export const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '56px',
  '& svg': {
    width: '24px',
    height: '24px'
  }
}));
