import React, { FC, ReactNode, useState } from 'react';
import { Route, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import GlobalHeader from '../../components/group-ui/global-header/global-header.component';
import { ProtectedRoute } from '../protected-route/protected-route.component';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { cleanupUserData } from '../../services/helpers/auth-helper/auth-helper.service';
import { UserRole } from '../../enums/user-role.enum';
import { AppBar, Box, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SideMenu from '../../components/group-ui/side-menu/side-menu.component';
import CarofiLogo  from "../../../assets/svgs/carofi-logo.icon.svg?react";
import { UserTeams } from '../../enums/user-teams.enum';

/**
 * interface DefaultLayoutComponentProps
 */
interface DefaultLayoutComponentProps {
  children?: ReactNode;
  logoutHandler: () => void;
  addLayout?: boolean;
}

/**
 * functional component to render child route in Default layout
 * @param childern react element to be injected in component
 * @param userEmail user email
 * @param logoutHandler to handle user logout action
 */
const DefaultLayoutComponent: React.FC<DefaultLayoutComponentProps> = ({
  children,
  logoutHandler,
  addLayout
}) => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {addLayout ? (
        <AppBar>
          <Toolbar disableGutters>
            <Box aria-label="logo" sx={{ mt: 1.375 }}>
              <CarofiLogo style={{ width: '120px', height: '35px' }} />
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          <GlobalHeader eventHandlers={{ logoutHandler, toggleMobileMenu: handleDrawerToggle }} />
          <SideMenu mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: theme.palette.background.default,
          height: 'auto',
          [theme.breakpoints.up('sm')]: {
            mt: theme.spacing(8),
            width: 'calc(100vw - 266px)'
          },
          [theme.breakpoints.up('xs')]: {
            mt: theme.spacing(7),
            width: '100vw'
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

/**
 * interface DefaultRouteProps
 */
interface DefaultRouteProps {
  component: FC<any>;
  // path: string;
  authenticationRequired: boolean;
  permittedList?: UserRole[];
  permittedTeamsList?: UserTeams[];
  // userEmail?: string;
  // userRole?: UserRole;
  // isAuthenticated: boolean;
  addLayout?: boolean;
  children?: ReactNode;
}

/**
 * functional component to handle rendering routes in default layout
 * @param component react component to be rendered
 * @param userEmail user email
 * @param isAuthenticated user authentication status
 * @param authenticationRequired route config whether route will be protected or not
 */
const DefaultRoute: React.FC<DefaultRouteProps> = ({
  component: Component,
  // userEmail,
  permittedList,
  permittedTeamsList,
  // userRole,
  // isAuthenticated,
  authenticationRequired,
  children,
  addLayout,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const logoutUser = () => {
    Store.addNotification({
      title: t('SHARED.HEADER.NOTIFICATIONS.LOGOUT_SUCCESS.TITLE'),
      message: t('SHARED.HEADER.NOTIFICATIONS.LOGOUT_SUCCESS.MESSAGE'),
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    setTimeout(() => {
      cleanupUserData();
      navigate(location.pathname);
    }, 1500);
  };
  return authenticationRequired ? (
    <ProtectedRoute
      permittedList={permittedList}
      permittedTeamsList={permittedTeamsList}
      component={(matchProps) => (
        <DefaultLayoutComponent addLayout={addLayout} logoutHandler={logoutUser}>
          <Component {...matchProps} />
        </DefaultLayoutComponent>
      )}
    />
  ) : (
    <Route {...rest}>
      <DefaultLayoutComponent addLayout={addLayout} logoutHandler={logoutUser}>
        <Component {...rest} />
      </DefaultLayoutComponent>
    </Route>
  );
};

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
// function mapStateToProps(state: RootState) {
//   console.log('state.auth', state.auth);
//   return {
//     isAuthenticated: !!state.auth.accessToken,
//     userRole: state.auth.role,
//     userEmail: state.auth.email
//   };
// }

export default DefaultRoute;
