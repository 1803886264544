import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../../shared/config/routes-config';
import { UserRole } from '../../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../../shared/layouts/default/default.layout';

const BrandsListContainer = React.lazy(
  () => import('../../containers/brands-list/brands-list.container')
);

export const BrandsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate to={(APP_ROUTES.BRANDS_MODULE.CHILDREN as RouteConfig).BRANDS_LIST.FULL_PATH} />
    )
  },
  {
    path: (APP_ROUTES.BRANDS_MODULE.CHILDREN as RouteConfig).BRANDS_LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.E_COMMERCE_MANAGER]}
          component={BrandsListContainer}
        />
      </React.Suspense>
    )
  }
];
