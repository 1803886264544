import React, { useState } from 'react';

import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import CarofiLogo  from "../../../../../src/assets/svgs/carofi-logo.icon.svg?react";
import ArrowDown  from "../../../../../src/assets/svgs/arrow-down.icon.svg?react";
import { AppBarHeader } from './global-header.styles';

const mobileOnlyElement = {
  display: { xs: 'flex', md: 'none' }
};

const webOnlyElement = {
  display: { xs: 'none', md: 'flex' }
};

export interface GlobalHeaderProps {
  eventHandlers: {
    logoutHandler?: () => void;
    toggleMobileMenu: () => void;
  };
}

/**
 * @param logoutHandler to handle when user logout
 * @param toggleMobileMenu state to handle hide/show side menu in mobile view
 */

export const GlobalHeader: React.FC<GlobalHeaderProps> = ({ eventHandlers }) => {
  const { logoutHandler, toggleMobileMenu } = eventHandlers;
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const displayName = useSelector((state: RootState) => state.auth.name);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBarHeader position="fixed">
      <Toolbar disableGutters>
        <Box sx={{ ...mobileOnlyElement, flexGrow: { xs: 1, md: 0 } }}>
          <IconButton aria-label="openDrawer" onClick={toggleMobileMenu}>
            <MenuIcon color="primary" fontSize="large" />
          </IconButton>
        </Box>

        <Box aria-label="logo" sx={{ flexGrow: { xs: 1, md: 0 }, mt: 1.375 }}>
          <CarofiLogo style={{ width: '120px', height: '35px' }} />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ ...webOnlyElement, flexGrow: 1 }}
        >
          {/* TO-DO: show user image */}
          <Avatar
            aria-label="avatar_Web"
            variant="rounded"
            alt={displayName}
            src="/broken-image.jpg"
          />
          {/* TO-DO: show user name */}
          <Typography aria-label="userName" variant="h5" color="textPrimary">
            {displayName || 'No name available'}
          </Typography>
          <IconButton aria-label="openUserMenu_Web" onClick={handleOpenUserMenu}>
            <ArrowDown style={{ marginLeft: theme.spacing(0.5) }} />
          </IconButton>
        </Stack>

        <Box sx={{ flexGrow: 0, ...mobileOnlyElement }}>
          <IconButton aria-label="openUserMenu_Mob" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {/* TO-DO: show user image */}
            <Avatar
              aria-label="avatar_Mob"
              variant="rounded"
              alt={displayName}
              src="/broken-image.jpg"
            />
          </IconButton>

          <Menu
            sx={{ mt: theme.spacing(5.75) }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="Notifications" sx={mobileOnlyElement} onClick={handleCloseUserMenu}>
              <Typography aria-label="menuNotifications" variant="h6" component="div">
                Notifications
              </Typography>
            </MenuItem>
            <Divider sx={mobileOnlyElement} />
            <MenuItem key="logout" onClick={logoutHandler}>
              <Typography aria-label="logout" variant="h6" component="div">
                logout
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBarHeader>
  );
};

export default GlobalHeader;
