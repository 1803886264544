// tslint:disable: no-console

if (process.env.NODE_ENV === 'development') {
  console.group();
  console.info('*** GA Tracking ID *** ', import.meta.env.VITE_GA_ID);
  console.info('*** LogRocket Tracking ID *** ', import.meta.env.VITE_LOG_ROCKET_ID);
  console.info('*** Amplitude Tracking ID *** ', import.meta.env.VITE_AMPLITUDE_ID);
  console.groupEnd();
}

export const TRACKING_GA_APP_ID = import.meta.env.VITE_GA_ID || 'UA-XXXXXX-X';
export const TRACKING_AMPLITUDE_ID = import.meta.env.VITE_AMPLITUDE_ID || 'XXXXXXXXXX';

export const SURVEY_MODULE_TRACKING = {
  SUB_VIEWS: {},
  EVENTS: {}
};
