import { ApiResponse } from 'apisauce';
import { UserAuthModel } from '../../models/user-auth.model';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import api from '../../config/http.config';
import { ResponseModel } from '../../interfaces/response-model.interface';
import { AddUserDataModel, UserModel } from '../../models/user.model';
import debugConfig from '../../config/debug.config';
import userApiFixtureService from '../fixtures/user-api.service';
import { SearchUserModel } from '../store/global-add-user.store';
import { UsersPayload } from '../../../Modules/UserManagement/shared/types/search-users.types';

const apis = {
  registerUser: (user: AddUserDataModel): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/register', user),
  loginUser: (email: string, password: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/login', { email, password }),
  changePassword: (
    token: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/changePassword', { token, password }),
  forgotPassword: (email: string): Promise<ApiResponse<{}, ErrorInfo>> =>
    api.post('/auth/forgotPassword', { email }),
  validateToken: (): Promise<ApiResponse<{}, ErrorInfo>> => api.get('/auth/verifyToken'),
  refreshToken: (refreshToken: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/refreshToken', { refreshToken }),
  getAllUsers: (query: UsersPayload): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> =>
    api.post('/users/search', query),
  addUser: (userModel: UserModel): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.post('/users/', userModel),
  getUserByPhoneNumber: ({
    phone,
    name,
    email,
    role
  }: SearchUserModel): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.post('/users/searchOne', { phone, name, email, role }),
  getUserById: (userId: string): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.get(`/users/${userId}`),
  addUnverifiedUser: (
    userData: AddUserDataModel
  ): Promise<ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>> =>
    api.post('/users/addUser', userData),
  updateUser: (
    userData: AddUserDataModel
  ): Promise<ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>> =>
    api.put(`/users/${userData.userId}`, userData),
  updateUserImage: (
    id: string,
    image: FormData
  ): Promise<ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>> =>
    api.put(`/users/image`, image, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  getSignedUrl: (key: string): Promise<ApiResponse<ResponseModel<{ url: string }>, ErrorInfo>> =>
    api.post(`/users/getSignedUrl`, {}, { data: { key } }),
  deleteUserImage: (id: string, key: string): Promise<ApiResponse<void, ErrorInfo>> =>
    api.delete(`/users/image`, {}, { data: { id, key } })
};

// TODO: Enhance the way of mocking APIs
export const userApi = debugConfig.useFixtures ? userApiFixtureService : apis;
