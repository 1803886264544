import { memo, useState } from 'react';
import { ActiveMenuItems, MenuItemProps } from '../shared/types/types';
import MultiLevel from './multi-level';
import SingleLevel from './single-level';

interface MenuItemComponentProps extends MenuItemProps {
  isCollapsed: boolean;
}

const MenuItem = (props: MenuItemComponentProps) => {
  const { item, isCollapsed } = props;
  const [isActive, setIsActive] = useState<ActiveMenuItems>({});
  const [selectedIndex, setSelectedIndex] = useState<string>('');

  const Component = item.CHILDREN ? MultiLevel : SingleLevel;

  return (
    <Component
      item={item}
      isActive={isActive}
      setIsActive={setIsActive}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      isCollapsed={isCollapsed}
    />
  );
};

export default memo(MenuItem);
